<template lang="html">

  <form v-on:submit.prevent="submitCategory(select.id)" method="get">
    <b-field>
      <b-select placeholder="Select a Category" v-model="select.id">
        <option
          v-for="option in categories"
          :value="option.id"
          :key="option.id">
          {{ option.name }}
        </option>
      </b-select>
      <div class="control">
        <button class="button" type="submit">Apply</button>
      </div>
      <div class="control">
        <button class="button" type="submit" @click="clearCategory();">Clear Search</button>
      </div>
    </b-field>
  </form>

</template>

<script>
import axios from 'axios';
export default {
  data: () => {
    return {
      categories: [],
      select:{
          id: null,
          category_name: null
        }
    }
  },
  created: function () {
    axios({method: 'GET', url: '/api/v1/categories.json'})
    .then(response => {
      if (response.data.length != 0){
        this.categories = response.data;
        console.log(this.categories)
      }
    })
  },
  methods: {
    submitCategory(cat_id) {
      axios.get('/articles', {params: {category_id: cat_id}})
      .then(response => {
        if(response.status === 200) {
          let element = document.getElementById('articles');
          element.innerHTML = response.data;
          // window.document.body.parentNode.innerHTML = response.data;
        } else {
          console.log("Error applying filter.");
        }
      })
    },
    clearCategory() {
      this.select = {}
      window.location.reload();
    }
  }
}
</script>
